import React, { useEffect } from "react";
//  import './App.css';
import { useState } from "react";
const Footer = () => {
  return (
    <footer
    className="footer  mt-5 py-0 text-white  bg-cs-dark"
    id="footer-section"
    style={{ fontFamily: 'Comfortaa, sans-serif' }}
    >
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-4 mb-5  ">
          <div className="logo ">
            <img
              style={{ width: "60px" }}
              src="./assets/img/EMU.png"
              alt="Logo"
            />
          </div>
          <p className="mt-3  facebook"  style={{ fontFamily: 'Comfortaa, sans-serif' }}>
          At EMU (Economic Monetary Unit), our vision is to create an immersive and dynamic digital universe where users can explore, interact, and thrive.
          </p>
          <div className="d-dlex">
            <a
              className="nav-link d-flex gap-1 text-decoration-none p-0"
              href="mailto:contact@emucoin.io"
              target="_blank"
            >
              <h6 className="text-light"  style={{ fontFamily: 'Comfortaa, sans-serif' }}>Office Email</h6>
              <h6 style={{ fontFamily: 'Comfortaa, sans-serif' }}>:</h6>
              <h6 className="" style={{ color: "cyan",fontFamily: 'Comfortaa, sans-serif' }}>
                contact@emucoin.io
              </h6>
            </a>
          </div>
        </div>
    
        <div className="col-md-8 mb-4">
          <div className="row">
            <div className="col-md-4 mb-4">
              <h5 className=" mb-4 explore">EXPLORE MORE</h5>
              <ul className="list-unstyled">
                <li className="mb-3 ">
                  <a
                    className="text-unique-grey text-decoration-none emu"
                    href="https://whitepaper.emucoin.io/emu-economic-monetary-unit/virtual-gaming"
                  >
                    EMU Virtual Gaming
                  </a>
                </li>
                <li className="mb-3 ">
                  <a
                    className="text-unique-grey text-decoration-none emu"
                    href="https://whitepaper.emucoin.io/emu-economic-monetary-unit/emu-debit-card"
                  >
                    EMU Debit Card
                  </a>
                </li>
                <li className="mb-3 ">
                  <a
                    className="text-unique-grey text-decoration-none emu"
                    href="https://whitepaper.emucoin.io/emu-economic-monetary-unit/virtual-shopping-in-emu"
                  >
                    Virtual Shopping in EMU
                  </a>
                </li>
                <li className="mb-3 ">
                  <a
                    className="text-unique-grey text-decoration-none emu"
                    href="https://whitepaper.emucoin.io/emu-economic-monetary-unit/emu-real-estate"
                  >
                    EMU Real Estate
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 mb-4">
              <h5 className="explore mb-4">MENU</h5>
              <ul className="list-unstyled">
                <li className="mb-3 ">
                  <a
                    className="text-unique-grey text-decoration-none emu"
                    href="#"
                    
                  >
                    Home
                  </a>
                </li>
                <li className="mb-3 ">
                  <a
                    className="text-unique-grey text-decoration-none emu"
                    href="https://whitepaper.emucoin.io/"
                  >
                    Whitepaper
                  </a>
                </li>
                <li className="mb-3 ">
                  <a
                    className="text-unique-grey text-decoration-none emu"
                    href="#tokenomics-section"
                  >
                    Tokenomics
                  </a>
                </li>
                <li className="mb-3 ">
                  <a
                    className="text-unique-grey text-decoration-none emu"
                    href="#roadmap-section"
                  >
                    Roadmap
                  </a>
                </li>
                <li className="mb-3 ">
                  <a
                    className="text-unique-grey text-decoration-none emu"
                    href="#contact-section"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 mb-4">
              <h5 className="explore mb-4">JOIN OUR COMMUNITY</h5>
    
              <div className="d-flex mt-2">
              <a className="nav-link active" aria-current="page" href="https://www.facebook.com/" target="target_blank">
                <div className="">
                  <img style={{width:"30px"}} src="/assets/img/facebook.png" />
                </div>
                </a>
                <a className="nav-link active" aria-current="page" href="https://x.com/?lang=en" target="_blank">
                <div className="">
                  <img style={{width:"30px"}} src="/assets/img/twittericon.png" />
                </div>
    </a>
    <a className="nav-link active" aria-current="page" href="https://web.telegram.org/a/" target="_blank"> 
    <div className="">
                  <img style={{width:"30px"}} src="/assets/img/telegram.png" />
                </div>
                </a>
                <a className="nav-link active" aria-current="page" href="https://www.instagram.com/" target="_blank">   
                   <div className="">
                  <img style={{width:"30px"}} src="/assets/img/instagram.png" />
                </div>
                </a>
                <a className="nav-link active" aria-current="page" href="https://in.linkedin.com/" target="_blank">   
                   <div className="">
                  <img style={{width:"30px"}} src="/assets/img/linkedinicon.png" />
                </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div
        className="d-flex justify-content-center footerend"
       
      >
        <div className="emucoin mt-3" >
          <a href="#" className="text-white" target="_blank" rel="noopener"  style={{ fontFamily: 'Comfortaa, sans-serif' }}>
            EMUCOIN
          </a>{" "}
          © 2024. All Rights Reserved.
        </div>
      </div>
    </div>
    </footer>
  );
};

export default Footer;
