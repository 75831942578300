import logo from "./logo.svg";
import "./App.css";
import "./style.css";
import React, { useRef, useEffect } from "react";
import Header from "../src/components/Header";
import Counter from "../src/components/Counter";
import Avatar from "../src/components/Avatar";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../src/components/Footer";
function App() {
  const videoRef = useRef(null);
  const videoRefSec = useRef(null);

  useEffect(() => {
    // Initialize AOS with options
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      once: false, // Ensure animations happen every time elements come into view
      mirror: false, // Adjust based on your preference
    });

    // Set up video event listener for the first video
    const video = videoRef.current;

    const handleVideoEnded = () => {
      video.currentTime = 0; // Reset video playback to the beginning
      video.play(); // Restart video playback
    };

    video.addEventListener("ended", handleVideoEnded);

    // Clean up event listener on component unmount
    return () => {
      video.removeEventListener("ended", handleVideoEnded);
    };
  }, []);

  useEffect(() => {
    // Set up video event listener for the second video
    const videosec = videoRefSec.current;

    const handleVideoEnded1 = () => {
      videosec.currentTime = 0; // Reset video playback to the beginning
      videosec.play(); // Restart video playback
    };

    videosec.addEventListener("ended", handleVideoEnded1);

    // Clean up event listener on component unmount
    return () => {
      videosec.removeEventListener("ended", handleVideoEnded1);
    };
  }, []);

  return (
    <>
      <Header />
      <main>
        <section
          className="d-flex align-items-end text-white py-lg-5 p-0 section1 mt-5"
          data-aos="fade-up"
        >
          <div className="container">
            <div className="row align-items-end pt-5 pb-4 border-bottom">
              {/* <div className="col-1 ">
            <a
              className="btn btn-outline-light rounded-circle btn-purple mb-5"
              href="#content"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 492.004 492.004"
                xmlSpace="preserve"
              >
                <g>
                  {" "}
                  <g>
                    {" "}
                    <path d="M484.14,226.886L306.46,49.202c-5.072-5.072-11.832-7.856-19.04-7.856c-7.216,0-13.972,2.788-19.044,7.856l-16.132,16.136   c-5.068,5.064-7.86,11.828-7.86,19.04c0,7.208,2.792,14.2,7.86,19.264L355.9,207.526H26.58C11.732,207.526,0,219.15,0,234.002   v22.812c0,14.852,11.732,27.648,26.58,27.648h330.496L252.248,388.926c-5.068,5.072-7.86,11.652-7.86,18.864   c0,7.204,2.792,13.88,7.86,18.948l16.132,16.084c5.072,5.072,11.828,7.836,19.044,7.836c7.208,0,13.968-2.8,19.04-7.872   l177.68-177.68c5.084-5.088,7.88-11.88,7.86-19.1C492.02,238.762,489.228,231.966,484.14,226.886z" />{" "}
                  </g>
                </g>
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
              </svg>
            </a>
          </div> */}
              <div className="col-lg-9 col-12 px-5">
                <h3 className="text-uppercase h2 mb-0">
                  Experience next-level gaming in the EMU Metaverse, where
                  play-to-earn meets immersive, interactive adventures.
                </h3>
              </div>
              {/* <div className="col-lg-4 col-12">
            <div className="d-flex align-items-end justify-content-lg-end justify-content-center mt-lg-0 mt-4">
              <a href="https://www.xbox.com/" target="_blank" className="me-3">
                <img
                  src="./assets/img/XBOX.svg"
                  alt="Xbox"
                  width={132}
                  height={40}
                />
              </a>
              <a href="https://www.playstation.com/" target="_blank">
                <img
                  src="./assets/img/PlayStation.svg"
                  alt="PlayStation"
                  width={157}
                  height={32}
                />
              </a>
            </div>
          </div> */}
            </div>
          </div>
        </section>
        <section
          className="d-flex align-items-center text-white big-titles pt-lg-0 pt-4 pb-5 section2"
          data-aos="fade-down"
        >
          <div className="container text-left">
            <h1 className="display-1 fw-bold text-uppercase">
              A Decentralised game backed
            </h1>
            <h1 className=" custom-colors display-1 fw-bold text-gradient text-uppercase">
              by Binance Blockchain
            </h1>
          </div>
        </section>
        {/*} <section className="bg-dark text-white">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <div className="ratio ratio-16x9">
              <video
                className="w-100"
                src="assets/Pexels-Videos-2759479.mp4" autoPlay muted
                controlsList="nodownload"
              />
            </div>
          </div>
        </div>
      </div>
    </section> */}

        <section className="bg-dark text-white">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12">
                <div className="ratio ratio-16x9">
                  <video
                    ref={videoRef}
                    className="w-100"
                    src="assets/Pexels-Videos-2759479.mp4"
                    autoPlay
                    muted
                    controlsList="nodownload"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="background-dark text-white py-lg-5 py-4"
          data-aos="fade-up"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-12 px-lg-5 px-4">
                <h2 className="fw-bold h3 text-uppercase w-lg-75 w-100">
                  The EMU metaverse offers an unparalleled virtual gaming
                  experience, designed to immerse players in a dynamic and
                  interactive digital world.
                </h2>
              </div>
              {/* <div className="col-md-6">
            <form className=" row align-items-center">
              <div className="col-md-8 px-lg-0 px-4">
                <div className="form-group me-2 flex-grow-1">
                  <input
                    type="email"
                    className="border-only-end background-border-box w-100 text-white "
                    placeholder="Enter Your Email Address"
                    required=""
                  />
                </div>
                <div className="form-group mt-1 me-2 mt-3">
                  <input type="checkbox" id="privacyPolicy" required="" />
                  <label htmlFor="privacyPolicy" className="form-check-label">
                    I agree to the{" "}
                    <a
                      href="/privacy-policy/"
                      target="_blank"
                      className="text-decoration-none text-white"
                    >
                      Privacy Policy
                    </a>
                    .
                  </label>
                </div>
              </div>
              <div className="col-md-4 text-end ">
                <button type="submit" className=" btn text-white">
                  <i className=" fas fa-paper-plane mt-3" /> Subscribe
                </button>
              </div>
            </form>
          </div> */}
            </div>
          </div>
        </section>
        <section className="text-white py-lg-5 py-0" data-aos="fade-up">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 text-center">
                <img
                  src="./assets/img/metaverse.png"
                  className="img-fluid"
                  alt="Modern Ideas"
                />
              </div>
              <div className="col-md-7">
                <span className="text-uppercase">THE EMU METAVERSE</span>
                <h1 className="fw-bold h1 text-uppercase mt-3">
                  EXPLORE A LIMITLESS DIGITAL UNIVERSE
                </h1>
                <p className="mt-4 text-unique-grey">
                  The Economic Monetary Unit (EMU) is a cutting-edge
                  cryptocurrency designed for use within the rapidly expanding
                  metaverse ecosystem. As digital realms continue to evolve, EMU
                  aims to provide a stable and efficient medium of exchange for
                  virtual transactions, offering users a seamless way to buy,
                  sell, and trade digital assets. EMU leverages blockchain
                  technology to ensure transparency, security, and
                  decentralization, making it an ideal currency for virtual
                  economies. With its focus on interoperability, EMU enables
                  users to interact across different metaverse platforms,
                  enhancing the fluidity and cohesion of the digital experience.
                  This positions EMU as a pivotal player in the future of
                  digital finance and virtual interactions.
                </p>
                <a
                  href="https://whitepaper.emucoin.io/overview/emu-metaverse"
                  className="text-decoration-none text-white "
                >
                  <span className="underline_anim underline_do_hover border-bottom pb-2">
                    Read More
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section
          className="position-relative text-white py-4"
          data-aos="fade-up"
        >
          <div className="w-100 text-center">
            <div className="marquee">
              <span className="display-1 fw-bold text-purple">
                EMU IS GOING TO LAUNCH 1st DECENTRALIZED WEB3 CASINO
              </span>
              <span className="display-1 fw-bold text-purple">
                EMU IS GOING TO LAUNCH 1st DECENTRALIZED WEB3 CASINO
              </span>
              <span className="display-1 fw-bold text-purple">
                EMU IS GOING TO LAUNCH 1st DECENTRALIZED WEB3 CASINO
              </span>
            </div>
          </div>
        </section>
        <section className=" text-white py-lg-5 py-0">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-3">
                <h3 className="h6">User</h3>
                <p className="display-4 fw-bold">
                  <Counter end={10000} duration={100} />+
                </p>
              </div>
              <div className="col-md-3">
                <h3 className="h6">Listing on Top Exchange</h3>
                <p className="display-4 fw-bold">
                  <Counter end={20} duration={1000} />+
                </p>
              </div>
              <div className="col-md-3">
                <h3 className="h6">Investor</h3>
                <p className="display-4 fw-bold">
                  <Counter end={10} duration={1000} />+
                </p>
              </div>
              <div className="col-md-3">
                <h3 className="h6">Partners</h3>
                <p className="display-4 fw-bold">
                  <Counter end={50} duration={1000} />+
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="text-white my-4 py-4" data-aos="fade-up">
          <div className="row g-0 align-items-center">
            <div className="col-md-7">
              <img
                src="./assets/img/nft.png"
                className="img-fluid w-100"
                alt="Graphics"
              />
            </div>
            <div className="col-md-5 p-lg-5 p-3">
              <h4 className="text-uppercase h6">NFT MARKETPLACE</h4>
              <h1 className="fw-bold text-uppercase mt-2">
                DISCOVER UNIQUE DIGITAL ASSETS
              </h1>
              <p className="mt-4 text-unique-grey">
                Explore the EMU NFT Marketplace, a hub for unique and rare
                digital assets. From art and collectibles to in-game items, find
                and acquire NFTs that enhance your virtual experience.
              </p>
              <p>
                EMU's NFT marketplace is a dynamic platform where users can buy,
                sell, and trade unique digital assets. These assets, represented
                as Non-Fungible Tokens (NFTs), include art, collectibles,
                in-game items, and more, each with verified ownership and
                authenticity on the blockchain. Economic Monetary Unit's
                marketplace features an intuitive and user-friendly interface,
                making it easy for users to browse, discover, and transact. With
                clear categories, search functionality, and detailed listings,
                finding and managing NFTs is seamless and straightforward.
              </p>
              <a
                href="https://whitepaper.emucoin.io/emu-economic-monetary-unit/emu-nft-marketplace"
                className="text-white btn btn-lg btn-cs mt-4 px-5 py-3 text-uppercase"
              >
                Learn More
              </a>
            </div>
          </div>
        </section>
        <section className="text-white py-lg-5 py-0" data-aos="fade-up">
          <div className="container text-center">
            <h3 className="mb-lg-5">Partners</h3>
            <div className="row justify-content-center mb-4 mb-lg-5">
              <div className="col-6 col-md-2 mb-2 mb-lg-4">
                <img
                  src="/assets/img/partner3.png"
                  className="img-fluid"
                  alt="Partner 1"
                />
              </div>
              <div className="col-6 col-md-2 mb-2 mb-lg-4">
                <img
                  src="/assets/img/partner1.png"
                  className="img-fluid"
                  alt="Partner 2"
                />
              </div>
              <div className="col-6 col-md-2 mb-2 mb-lg-4">
                <img
                  src="/assets/img/partner4.png"
                  className="img-fluid"
                  alt="Partner 3"
                />
              </div>
              <div className="col-6 col-md-2 mb-2 mb-lg-4">
                <img
                  src="/assets/img/partner2.png"
                  className="img-fluid"
                  alt="Partner 4"
                />
              </div>
              <div className="col-6 col-md-2 mb-2 mb-lg-4">
                <img
                  src="/assets/img/partner5.png"
                  className="img-fluid"
                  alt="Partner 5"
                />
              </div>
              <div className="col-6 col-md-2 mb-2 mb-lg-4">
                <img src="/assets/img/partner.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
        <section className="text-light" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="mb-5" />
                <div className="animation_type_block">
                  <h3 className="text-uppercase h6 fw-normal">
                    VIRTUAL SHOPPING IN EMU
                  </h3>
                  <h1 className="display-4 fw-bold text-uppercase">
                    EXPERIENCE NEXT-LEVEL <br /> VIRTUAL SHOPPING IN EMU
                  </h1>
                  <a
                    href="https://whitepaper.emucoin.io/emu-economic-monetary-unit/virtual-shopping-in-emu"
                    className="btn btn-primary btn-lg btn-cs py-3 px-5 text-uppercase mt-4"
                  >
                    More Information
                  </a>
                </div>
                <div className="mb-4" />
                <div className="mb-5" />
              </div>
            </div>
          </div>
        </section>
        <section
          className="position-relative text-center video-section mb-0 mb-lg-5 section3"
          data-aos="fade-up"
        >
          <div className="container-fluid p-0">
            <div>
              <video
                ref={videoRefSec}
                className="w-100 h-100"
                src="assets/img/section3.mp4"
                autoPlay
                muted
                controlsList="nodownload"
              />
            </div>
          </div>
        </section>
        <section
          className="position-relative text-white pt-5"
          data-aos="fade-down"
        >
          <div className="translate-middle w-100 text-center">
            <div className="marquee">
              <span className="display-1 fw-bold text-big-color">
                THE EMU DEBIT CARD SEAMLESSLY INTEGRATES WITH EMU COIN
              </span>
              <span className="display-1 fw-bold text-big-color">
                THE EMU DEBIT CARD SEAMLESSLY INTEGRATES WITH EMU COIN
              </span>
              <span className="display-1 fw-bold text-big-color">
                THE EMU DEBIT CARD SEAMLESSLY INTEGRATES WITH EMU COIN
              </span>
            </div>
          </div>
        </section>
        <section className="d-flex text-white align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7 position-relative">
                <img
                  src="./assets/img/debit.png"
                  className="img-fluid"
                  alt="image-about-copyright"
                />
                <div className="position-absolute text-white purple-bg image-overlap-text">
                  <h6 className="text-uppercase h4">
                    Seamless Integration with EMU COIN
                  </h6>
                  <a
                    href="https://whitepaper.emucoin.io/emu-economic-monetary-unit/emu-debit-card"
                   
                    className="btn-outer d-inline-block float-end bg-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={30}
                      height={30}
                      fill="currentColor"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.854 4.646a.5.5 0 0 1 0 .708L8.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M4.5 8a.5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z"
                      />
                    </svg>
                  </a>
                </div>
                <div className="position-absolute text-white d-flex align-items-center justify-content-center"></div>
              </div>
              <div className="col-md-5 py-lg-0 py-4 text-white">
                <h2 className="mb-4 h6">ECONOMIC MONETARY UNIT</h2>
                <h1 className="mb-4 h1">Debit Card</h1>
                <p className="text-unique-grey">
                  The EMU Debit Card seamlessly integrates with EMU COIN, the
                  native currency of the EMU metaverse. It allows users to spend
                  their EMU COIN both within the metaverse and in the real
                  world, bridging the gap between virtual and physical
                  economies.
                </p>
                <div className="mt-5">
                  <h6 className="d-inline-block">
                    <a href="#" className="text-white text-decoration-none">
                      Real-World Purchases
                    </a>
                  </h6>
                  <h6 className="d-inline-block float-end">
                    <a
                      href="#"
                      className="text-white text-decoration-none float-end"
                    ></a>
                  </h6>
                  <p className="text-dark-grey">BUY &amp; ENJOY</p>
                  <p className="text-unique-grey w-75">
                    With the EMU Debit Card, you can make real-world purchases
                    wherever traditional debit cards are accepted. Convert your
                    EMU COIN to fiat currency in real-time, enabling you to use
                    your virtual earnings for everyday expenses, shopping, and
                    more.
                  </p>
                </div>
                <div className="mt-5">
                  <h6 className="d-inline-block">
                    <a href="#" className="text-white text-decoration-none">
                      Secure Transactions
                    </a>
                  </h6>
                  <h6 className="d-inline-block float-end">
                    <a href="#" className="text-white text-decoration-none"></a>
                  </h6>
                  <p className="text-dark-grey">Security</p>
                  <p className="text-unique-grey w-75">
                    Security is paramount with the EMU Debit Card. Transactions
                    are protected with advanced encryption and blockchain
                    technology, ensuring your funds are safe. Enjoy peace of
                    mind knowing that your virtual and real-world transactions
                    are secure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="out_team  py-lg-5" id="our_team" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="mb-lg-5 mb-0" />
                <div className="animation_type_block">
                  <h3 className="text-uppercase text-unique-grey h6 fw-normal">
                    Meet Our
                  </h3>
                  <h1 className="display-4 text-white fw-bold text-uppercase">
                    Team
                  </h1>
                </div>
              </div>
            </div>
            <div className="row justify-content-evenly mt-5">
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                <div className="team__box background-dark shadow-sm rounded border text-center position-relative p-4 mx-4">
                  <h4 className="h4 text-light">Jack</h4>
                  <p className="my-4 text-unique-grey">CTO</p>
                  <Avatar size="50%" src="assets/img/office-man.png" />
                  <div className="d-flex gap-3 justify-content-center mt-4">
                    <a
                      href="https://x.com/?lang=en"
                      className="fab text-white fa-twitter"
                     target="_blank"
                    />
                    <a
                      href="https://in.linkedin.com/"
                      className="fab fa-linkedin text-white "
                          
                            target="_blank"
                    />

                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                <div className="team__box background-dark shadow-sm rounded border text-center position-relative p-4 mx-4">
                  <h4 className="h4 text-light">Jason Roy</h4>
                  <p className="my-4 text-unique-grey">MD</p>
                  <Avatar size="50%" src="assets/img/office-man.png" />

                  <div className="d-flex gap-3 justify-content-center mt-4">
                    <a
                      href="https://x.com/?lang=en"
                      className="fab text-white fa-twitter "
                           
                            target="_blank"
                    />
                    <a
                      href="https://in.linkedin.com/"
                      className="fab fa-linkedin text-white "
                           target="_blank"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                <div className="team__box background-dark shadow-sm rounded border text-center position-relative p-4 mx-4">
                  <h4 className="h4 text-light">Marsh</h4>
                  <p className="my-4 text-unique-grey">COD</p>
                  <Avatar size="50%" src="assets/img/office-man.png" />

                  <div className="d-flex gap-3 justify-content-center mt-4">
                    <a
                      href="https://x.com/?lang=en"
                      className="fab text-white fa-twitter "
                       target="_blank"
                    />
                    <a
                      href="https://in.linkedin.com/"
                      className="fab fa-linkedin text-white "
                       target="_blank"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="roadmap pt-5 text-center"
          id="roadmap-section"
          data-aos="fade-down"
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="mb-lg-5 mb-0" />
                <div className="animation_type_block">
                  <h3 className="text-uppercase text-unique-grey h6 fw-normal">
                    See Our
                  </h3>
                  <h1 className="display-4 text-white fw-bold text-uppercase">
                    Roadmap
                  </h1>
                </div>
              </div>
            </div>
            <img className="img-fluid" src="./assets/img/roadmap.png" />
          </div>
        </section>

        <section className="swap-ui text-white" data-aos="fade-up">
          <div className="container mt-3 mt-lg-5">
            <div className="row">
              <div className="col-md-7 mt-lg-0 mt-3 mt-lg-5">
                <div className="d-flex justify-content-center">
                  <ul className="nav nav-tabs border-0 border-radius-0 mb-3">
                    <li className="nav-item me-3">
                      <a className="nav-link active bg-purple-shade" href="#">
                        B
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link bg-purple-shade " href="#">
                        Sell
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="d-flex justify-content-center pt-2 pt-lg-5">
                  <div className="exchange-card w-75 p-4">
                    <div className="swap-header">
                      <h4 className="mb-3 d-inline-block">Exchange</h4>
                      <a className="mb-3 mt-2 h6 text-dark-grey text-decoration-none d-inline-block float-end">
                        Connect wallet
                      </a>
                    </div>
                    <p className="text-muted mb-4">
                      Trade tokens in an instant
                    </p>
                    <div className="mb-3">
                      <label htmlFor="fromAmount" className="form-label">
                        From
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          id="fromAmount"
                          placeholder={0.0}
                        />
                        <button
                          className="btn btn-outline-secondary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="https://cryptologos.cc/logos/tether-usdt-logo.png?v=032"
                            alt="BNB"
                            width={20}
                            height={20}
                          />{" "}
                          USDT
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <a className="dropdown-item" href="#">
                              BNB
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              ETH
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              BTC
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="text-center mb-3">
                      <i className="bi bi-arrow-down" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="toAmount" className="form-label">
                        To
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          id="toAmount"
                          placeholder={0.0}
                        />
                        <button
                          className="btn btn-outline-secondary dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="./assets/img/EMU.png"
                            alt="BNB"
                            width={20}
                            height={20}
                          />{" "}
                          EMU
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <a className="dropdown-item" href="#">
                              BNB
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              ETH
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              BTC
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <button className="btn btn-primary purple-bg w-100">
                      Swap
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-5 mt-2 p-lg-5">
                <h1 className="fw-bold text-uppercase mt-2">
                  ECONOMIC MONETARY UNIT'S SWAP COMING SOON
                </h1>
                <p className="mt-4 text-unique-grey">
                  EMU SWAP allows users to exchange any cryptocurrency they hold
                  on wallet and swap any other cryptocurrency that EMU SWAP
                  lists.
                </p>
                <p>
                  This feature makes it easier to buy new assets even if a user
                  doesn't have fiat currency in their account.
                </p>
                <a
                  href="https://whitepaper.emucoin.io/"
                  className="text-white btn btn-lg btn-cs mt-4 px-5 py-3 text-uppercase"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </section>
        <section
          className="vx_tokenomicsmain py-5"
          id="tokenomics-section"
          data-aos="fade-down"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-md-10">
                <div className="vx_section-title text-center mb-60">
                  <span className="text-dark-grey text-uppercase">
                    <span>Tokenomics at a Glance</span>
                  </span>
                  <h3 className="text-white h2 text-uppercase">Tokenomics</h3>
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-lg-5">
              <div className="col-lg-6">
                <div className="vx_tokenomicsmain_images mt-4 px-5">
                  <img src="./assets/img/tokenomics.svg" alt="tokenomics img" />
                </div>
              </div>
              <div className="col-lg-6 tokenomicstable mt-lg-0 mt-5">
                <h4 className="h4 text-white mb-4">Coin Details</h4>
                <ul>
                  <li>
                    <h5 className="text-white h6 mb-0 fw-normal">
                      Chain Network :
                    </h5>
                    <span className="text-dark-grey">Binance Smart Chain</span>
                  </li>
                  <li>
                    <h5 className="text-white h6 mb-0 fw-normal">
                      Coin Name :
                    </h5>
                    <span className="text-dark-grey">
                      Economic Monetary Unit
                    </span>
                  </li>
                  <li>
                    <h5 className="text-white h6 mb-0 fw-normal">
                      Token Ticker :
                    </h5>
                    <span className="text-dark-grey">EMU</span>
                  </li>
                  <li>
                    <h5 className="text-white h6 mb-0 fw-normal">
                      Smart Contract :
                    </h5>
                    <a
                      href="https://polygonscan.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      0x000..0000
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="text-white py-lg-5 py-0" data-aos="fade-up">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 text-center">
                <img
                  src="./assets/img/benifit.png"
                  className="img-fluid"
                  alt="Modern Ideas"
                />
              </div>
              <div className="col-md-7">
                <h1 className="fw-bold h1 text-uppercase mt-3">
                  BENIFITS WITH EMU
                </h1>
                <p className="mt-4 text-unique-grey">
                  EMU COIN is a multifaceted utility token that underpins the
                  economic and governance structures of the EMU metaverse. Its
                  diverse applications make it an essential asset for users
                  looking to maximize their experience and influence within the
                  digital world. Serving as the primary medium of exchange, EMU
                  COIN facilitates seamless transactions for virtual real
                  estate, in-game items, NFTs, and various services, enhancing
                  user experience. It empowers users to customize their digital
                  environments, shop in virtual malls, and personalize avatars.
                  As a core component of the reward system, users earn EMU COIN
                  through quests, events, trading, and content creation, driving
                  community engagement. Governance rights via a Decentralized
                  Autonomous Organization (DAO) allow holders to influence
                  platform decisions. Real-world integration through the EMU
                  Debit Card lets users convert digital assets to fiat currency
                  for everyday expenses. Staking EMU COIN provides additional
                  rewards and network stability, while access to exclusive
                  content and a thriving marketplace ensure a vibrant ecosystem.
                  Plus, EMU COIN will be listed on the top 20 exchanges, further
                  enhancing its utility and accessibility.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="py-lg-5 py-0 my-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center text-white mb-4">
            <small>OUR BLOG</small>
            <h2 className="text-uppercase h1 mt-1 mb-3">Metaverse News</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card bg-dark text-white">
              <img
                src="./assets/img/post22-copyright-890x664.jpg"
                className="card-img-top"
                alt="Choose an avatar and enter the fray"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-white h6 text-uppercase">
                    Heroes, Hot Releases -{" "}
                  </small>
                  <span className="text-dark-grey ms-2"> June 15, 2024</span>
                </p>
                <h5 className="card-title text-uppercase h5">
                  Choose an avatar and Start Playing EMU Game.
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card bg-dark text-white">
              <img
                src="./assets/img/nft.png"
                className="card-img-top"
                alt="Mafia, cartels, and FBI on a mission"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-white h6 text-uppercase">
                    News -{" "}
                  </small>{" "}
                  <span className="text-dark-grey ms-2">July 15, 2024</span>
                </p>
                <h5 className="card-title text-uppercase h5">
                  EMU's NFT Marketplace is coming soon.
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card bg-dark text-white">
              <img
                src="./assets/img/post23-copyright-890x664.jpg"
                className="card-img-top"
                alt="Travel through time and space via Matrix"
              />
              <div className="card-body">
                <p className="card-text">
                  <small className="text-white h6 text-uppercase">
                    Hot Releases -{" "}
                  </small>
                  <span className="text-dark-grey ms-2"> May 14, 2024</span>
                </p>
                <h5 className="card-title text-uppercase h5">
                  Travel through time and space via EMU Metaverse.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}
        <section className="" id="contact-section" data-aos="fade-up">
          <div className="big-text-parent">
            <img className="w-100" src="./assets/img/text-writen.png" />
          </div>
        </section>
      </main>
   <Footer />
    </>
  );
}

export default App;
