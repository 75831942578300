import React, { useEffect } from "react";
//  import './App.css';
import { useState } from "react";
const Header = () => {
  const [stickyState, setstickyState] = useState(false);
  const [addBgState, setaddBgState] = useState(false);
  useEffect(() => {
    const handleHeader = () => {
      setstickyState(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleHeader);

    return () => {
      window.removeEventListener("scroll", handleHeader);
    };
  }, []);
  return (
    <header
      className={`fixed-top ${stickyState || addBgState ? "bg-transparent blurheader" : ""
        } header`}
      style={{ fontFamily: 'Comfortaa, sans-serif' }}
    >
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark bg-transparent">
        <div className="container">
          <a className="navbar-brand emulogo" href="#">
            <img src="./assets/img/EMU.png" alt="Gamic" className="logo" />
          </a>
          <button
            onClick={() => setaddBgState(!addBgState)}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={` collapse navbar-collapse `} id="navbarNav">
            <ul className="navbar-nav mb-2 mb-lg-0 text-center">
              <li className="nav-item mr-2 home">
                <a className="nav-link active" aria-current="page" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item me-2 home">
                <a className="nav-link" href="https://whitepaper.emucoin.io/">
                  Whitepaper
                </a>
              </li>
              <li className="nav-item me-2 home">
                <a className="nav-link" href="#tokenomics-section">
                  Tokenomics
                </a>
              </li>
              <li className="nav-item me-2 home">
                <a className="nav-link" href="#roadmap-section">
                  Roadmap
                </a>
              </li>
              <li className="nav-item me-2 home">
                <a className="nav-link" href="#contact-section">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
